<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 27" fill="none">
    <g stroke="currentColor" stroke-width="1.5">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15.276 5.056H4.609v5.333h10.667z" />
      <path stroke-linecap="round" stroke-linejoin="round" d="M18.832 21.944V2.39a.89.89 0 0 0-.889-.889h-16a.89.89 0 0 0-.889.889v19.555c0 .491.398.89.89.89h16a.89.89 0 0 0 .888-.89" />
      <path fill="currentColor" d="M5.637 14.389a.139.139 0 1 1-.278 0 .139.139 0 0 1 .278 0ZM10.083 14.389a.139.139 0 1 1-.278 0 .139.139 0 0 1 .277 0ZM14.528 14.389a.139.139 0 1 1-.278 0 .139.139 0 0 1 .278 0ZM5.637 18.833a.139.139 0 1 1-.278 0 .139.139 0 0 1 .278 0ZM10.083 18.833a.139.139 0 1 1-.278 0 .139.139 0 0 1 .277 0ZM14.528 18.833a.139.139 0 1 1-.278 0 .139.139 0 0 1 .278 0Z" />
      <path fill="#fff" stroke-linecap="round" stroke-linejoin="round" d="M16.166 25.5a5.778 5.778 0 1 0 0-11.556 5.778 5.778 0 0 0 0 11.556" />
      <path stroke-linecap="round" stroke-linejoin="round" d="m12.832 20.344 1.867 2 4.355-4.666" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgCalculatorCheck'
};
</script>
