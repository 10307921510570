import BaseResource from 'resources/base-resource';
import axios from 'axios';

class User extends BaseResource {
  static baseUrl = '/users/current.json';
  static urlTransformations = [];

  static allowedMessageRecipients() {
    return axios.get('/users/allowed_message_recipients.json');
  }

  static disconnectGoogle() {
    return axios.post('/revoke_auth/google_oauth2.json');
  }

  static connectedApis() {
    return axios.get('/users/connected_apis.json');
  }
}

export default User;
